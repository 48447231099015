"use client";
import React from "react";
import { AnimatedTooltip } from "@/components/ui/animated-tooltip";

import Image from "next/image";
import Tag from "@/components/shared/Tag";

interface TestimonialCardProps {
  quote: string;
  name: string;
  title: string;
  avatar: string;
  stats: { value: string; label: string }[];
  designation: string; // Add this line
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  quote,
  name,
  title,
  avatar,
  stats,
  designation,
}) => {
  const person = [{ id: 1, name, designation, image: avatar }];

  return (
    <div className="flex h-auto w-full flex-col rounded-[32px] border border-slate-100 bg-white px-7 py-4 md:h-[340px] md:w-[600px] md:flex-row md:py-6">
      <div className="flex flex-grow flex-col justify-between space-y-6 md:mr-6 md:w-3/5 md:space-y-0">
        <div className="flex flex-col space-y-4">
          <Image src="https://crayo-lp.vercel.app/quote.png" alt="quote" width={36} height={36} />
          <p className="text-md line-clamp-4 text-start md:text-lg">{quote}</p>
        </div>
        <div className="flex flex-row items-center space-x-2.5">
          <AnimatedTooltip items={person} />
          <div className="flex flex-col items-start pl-4 text-start">
            <p className="font-ambit-bold text-[16px]">{name}</p>
            <p className="font-ambit-regular text-[16px] text-slate-400">{title}</p>
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-row justify-center space-x-4 space-y-0 md:mt-0 md:w-2/5 md:flex-col md:space-x-0 md:space-y-4">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex aspect-square w-1/2 flex-col items-center justify-center rounded-3xl bg-crayoBlue/10 md:w-full md:p-3"
          >
            <h6 className="text-[18px] font-bold md:text-[24px]">{stat.value}</h6>
            <p className="whitespace-nowrap text-xs md:text-sm">{stat.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const testimonials = [
  {
    id: 1,
    quote:
      "After running channels with over 1 million subscribers, I built Crayo to solve my own scaling problems.",
    name: "Daniel Bitton",
    designation: "Clipper + content creator",
    title: "Over 1 billion views generated on Shorts",
    avatar: "https://crayo-lp.vercel.app/testimonialsDaniel.png",
    stats: [
      { value: "14", label: "Pages auto-clipped" },
      { value: "$100k+", label: "Monthly revenue" },
    ],
  },
  {
    id: 2,
    quote:
      "I've clipped for some of the biggest creators on the internet. Crayo is the tool I wish I had when I started.",
    name: "Musa Mustafa",
    designation: "Clipper + content creator",
    title: "Clipped for SNEAKO, Sidemen, and more",
    avatar: "https://crayo-lp.vercel.app/testimonialsMusa.png",
    stats: [
      { value: "4x", label: "Faster clipping" },
      { value: "30%", label: "Increase in retention" },
    ],
  },
];

const Testimonials = () => {
  return (
    <div className="flex flex-col items-center justify-between space-y-12 bg-gradient-to-b from-white to-[#F0F7FF] px-12 py-20 text-center sm:px-8 md:px-16 lg:px-40">
      <div className="flex w-full flex-col items-center space-y-5 md:w-2/3">
        <Tag label="Our Founders" icon="https://crayo-lp.vercel.app/iconHeart.png" />
        <h3 className="text-[32px] leading-[140%] md:text-[48px]">Built by the best.</h3>
      </div>
      <div className="flex w-full flex-col items-center justify-center space-y-10 md:flex-row md:space-x-10 md:space-y-0">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
